import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import LinkButton from '../../styled/link-button'
import InputGroup from '../../styled/input-group'

const CheckLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  .label {
    padding-right: 15px;
    white-space: nowrap;
  }
  .input {
    vertical-align: middle;
    margin-right: 5px;
    width: 25px;
  }
`
export const testChordRegex = /\b(A|B|C|D|E|F|G)b*#*(sus)*(dim)*m*M*7*5*4*6*2*(\s|\/*(A|B|C|D|E|F|G)(?!}))/
export const replaceChordRegex = /\b(A|B|C|D|E|F|G)b*#*(sus)*(dim)*m*M*7*5*4*6*2*(\s|\/*(A|B|C|D|E|F|G)(?!}))/g

export default ({ content, updateFn }) => {
  const [autoEnabled, setAutoEnabled] = useState(false)

  const chordable = testChordRegex.test(content)

  const replace = useCallback((text) => {
    try {
      const newText = text.replace(replaceChordRegex, (a, b, c) => {
        let start = 0
        let buffer = ''
        if (a.startsWith(' ')) {
          start = 1
          buffer = ' '
        }
        if (a.startsWith('\n')) {
          buffer = '\n'
          start = 1
        }
        let end = a.length
        if (a.endsWith('\n') || a.endsWith(' ')) {
          end = a.length - 1
        }
        return `${buffer}{${a.substring(start, end)}} `
      })
      updateFn(newText)
    } catch (e) {
      console.log('error in auto-chord replace: ', e)
    }
  })

  if (autoEnabled && chordable) {
    replace(content)
  }

  return (
    <InputGroup style={{ width: '100%', marginBottom: '0rem' }}>
      <CheckLabel>
        <input
          className='input'
          type='checkbox'
          name='metronomeAutoStart'
          checked={autoEnabled}
          onChange={() => setAutoEnabled(!autoEnabled)}
        />
        <div className='label'>Format detected chords while typing</div>
        <div className='label'>
          {!autoEnabled && chordable && <LinkButton onClick={() => replace(content)}>Chords detected, format now</LinkButton>}
          {!autoEnabled && !chordable && '(None detected)'}
        </div>
      </CheckLabel>
    </InputGroup>
  )
}
