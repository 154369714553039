import { replaceChordRegex } from '../auto-chord'

const mergeLines = (chordsLine, lyricsLine) => {
  let chord
  let adjustedIndex = 0
  let newLine = lyricsLine

  // for each chord, get its current position
  while ((chord = replaceChordRegex.exec(`${chordsLine} `)) != null) {
    const chordTxt = chord[0].trim()
    // adjust its index from previous inserts into the lyrics
    const chordIndex = chord.index + adjustedIndex
    // find where it goes in the lyrics line
    let insertIdx = 0
    if (chordIndex !== 0) {
      const nextSpaceIdx = newLine.indexOf(' ', chordIndex) > -1 ? newLine.indexOf(' ', chordIndex) : newLine.length
      const prevSpaceIdx = newLine.lastIndexOf(' ', chordIndex) > -1 ? newLine.lastIndexOf(' ', chordIndex) : 0
      // use whichever space is closer to the target index for the chord
      insertIdx = Math.abs(chordIndex - nextSpaceIdx) <= Math.abs(chordIndex - prevSpaceIdx) ? nextSpaceIdx : prevSpaceIdx
    }

    // insert it and adjust the index
    if (insertIdx === 0) {
      newLine = `${newLine.slice(0, insertIdx)}{${chordTxt}} ${newLine.slice(insertIdx)}`
    } else {
      newLine = `${newLine.slice(0, insertIdx)} {${chordTxt}}${newLine.slice(insertIdx)}`
    }

    adjustedIndex += chordTxt.length + 3
  }

  return newLine.trim()
}

const formatChordsOnlyLine = chordsLine => {
  const formatLine = `${chordsLine} ` // make sure trailing space for matcher
  try {
    return formatLine.replace(replaceChordRegex, (a) => {
      return ` {${a.trim()}}`
    })
  } catch (e) {
    console.log('error in formatChordsOnlyLine: ', e)
    return chordsLine
  }
}

const isHeadingLine = txt => {
  return /^\[.*\]$/.test(txt)
}

const isLineLyrics = a => {
  if (a === undefined || a.startsWith('[')) {
    return false
  }
  let line = `${a} `
  try {
    line = line.replace(replaceChordRegex, '')
    line = line.replace(/\s/g, '')
  } catch (e) {
    console.log('error in isLineLyrics: ', e)
  }
  return line.length > 0
}

const isLineAllChords = a => {
  let line = `${a} `
  if (a.trim().length === 0) {
    return false
  }

  try {
    line = line.replace(replaceChordRegex, '')
    line = line.replaceAll('N.C.', '')
    line = line.replace(/\s/g, '')
  } catch (e) {
    console.log('error in isLineAllChords: ', e)
  }

  console.log('line', line)
  return line.length === 0
}

export const looksLikeChordsAbovePaste = (content) => {
  const lines = content.match(/[^\r\n]+/g)

  for (let i = 0; i < lines.length; i++) {
    // is line all chords?
    const allChords = isLineAllChords(lines[i])

    if (allChords) {
      // merge with next line if its lyrics
      if (isLineLyrics(lines[i + 1])) {
        return true
      }
    }
  }
  return false
}

export default (content) => {
  console.log(content)
  const updatedLines = []
  const lines = content.match(/[^\r\n]+/g)

  for (let i = 0; i < lines.length; i++) {
    // is line all chords?
    const allChords = isLineAllChords(lines[i])
    console.log(lines[i])
    if (allChords) {
      console.log('^^^^ is all chords true')
      // merge with next line if its lyrics
      if (isLineLyrics(lines[i + 1])) {
        console.log('next line is lyrics, woooo!')
        updatedLines.push(mergeLines(lines[i], lines[i + 1]))
        i++ // skip the next line
      } else {
        console.log('next line NOT lyrics!')
        // just a chords line, push by itself
        updatedLines.push(formatChordsOnlyLine(lines[i]))
      }
    } else {
      // if current line is blank, and previous is title, don't add it
      if (lines[i].trim().length === 0 && isHeadingLine(lines[i - 1])) {
        // dont push the line
      } else {
        // if _some_ chords, replace chords in-line too
        updatedLines.push(formatChordsOnlyLine(lines[i]))
      }
    }
  }

  let prevLine = '?'

  const finalLines = []
  for (let j = 0; j < updatedLines.length; j++) {
    if (updatedLines[j].trim() === '') {
      if (prevLine !== '') {
        finalLines.push(updatedLines[j].trim())
      }
    } else {
      finalLines.push(updatedLines[j].trim())
    }
    prevLine = updatedLines[j].trim()
  }

  return finalLines.join('\n')
}
